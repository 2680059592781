export default class HomeItemsModel {
  id: number | null;
  nome_Botao: string | null;
  url_Botao: string | null;
  slide_Home_Urls: string[]; 
  createdAt: string | null;
  updatedAt: string | null;
  publishedAt: string | null;

  constructor(
    id: number | null,
    nome_Botao: string | null,
    url_Botao: string | null,
    slide_Home_Urls: string[], 
    createdAt: string | null,
    updatedAt: string | null,
    publishedAt: string | null
  ) {
    this.id = id;
    this.nome_Botao = nome_Botao;
    this.url_Botao = url_Botao;
    this.slide_Home_Urls = slide_Home_Urls; 
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.publishedAt = publishedAt;
  }
}
