import { ReactElement, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { FaBook } from "react-icons/fa6";
import LIVRO from "../../assets/img/livro.jpeg";
import PASSEIOS from "../../assets/img/passeios.jpg";
import { ACTIVITIES } from "../../core/app-urls";
import {
  ClickHereStyle,
  GoBackButton,
  ImageRelationshipCard,
  RelationshipCircularIcon,
  RelationshipDetailsCard,
  RelationshipDetailsCardContent,
  RelationshipDetailsPageCardTitle,
  RelationshipDetailsPageStyle,
  RelationshipDetailsPageTitle,
  RelationshipDetailsSubText,
  RelationshipDetailsSubText2,
  SecondColumnDetailsPage,
  StyledArrowIcon,
  WrapBackButton,
  WrapContent,
  WrapContent2,
} from "../../styles/Relationship";
import { PiAirplaneTiltDuotone } from "react-icons/pi";
import { FaPuzzlePiece } from "react-icons/fa6";

interface RelationshipDetails {
  icon: ReactElement;
  title: string;
  subtext: string;
  image: string;
  details: string;
  details2: string;
}

const relationshipDetails: Record<number, RelationshipDetails> = {
  1: {
    icon: <FaBook size={40} color="#1270B0" />,
    title: "Clube do livro",
    subtext: "",
    image: LIVRO,
    details: `O CLUBE DO LIVRO constitui-se atividade de caráter cultural que
              tem alcançado considerável êxito, em nossa Associação, desde que
              foi instituído em 2010. Trazendo encontros, discussões e boas
              memórias, nosso clube do livro esta esperando por voce!
              `,
    details2: `
              `,
  },
  2: {
    icon: <PiAirplaneTiltDuotone size={50} color="#1270B0" />,
    title: "Viagens e passeios",
    subtext: "",
    image: PASSEIOS,
    details: `Um programa que nos conecta com novos lugares, novas culturas e pessoas. Nossas viagens são bem planejadas e estruturadas para um tempo reunido de amigos, experiencias e ótimas lembranças..
              `,
    details2: `
            `,
  },
  3: {
    icon: <FaPuzzlePiece size={50} color="#1270B0" />,
    title: "Clube da memória",
    subtext: "",
    image: PASSEIOS,
    details: `Um encontro semanal que visa a estimulação mental através de
              diversos exercícios e jogos que desafiam a memória, atenção,
              linguagem e outras funções cognitivas. 
              `,
    details2: `Além de promover a saúde
              mental, o Clube da Memória também oferece um espaço para a
              interação social, incentivando os participantes a se engajarem em
              conversas, compartilharem histórias e fortalecerem vínculos com
              outras pessoas da mesma faixa etária.
            `,
  },
};

export default function RelationshipDetailsPage() {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const activityId = id ? parseInt(id, 10) : null; // Converta o id para número

  const handleCardClick = () => {
    setIsCardExpanded(!isCardExpanded);
  };

  const handleBackButton = () => {
    navigate("/");
  };

  const activity = activityId !== null ? relationshipDetails[activityId] : null;

  return (
    <>
      <Header />
      <RelationshipDetailsPageStyle>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para página inicial</GoBackButton>
          </WrapBackButton>
        </WrapContent>
        <WrapContent2>
          {activity ? (
            <>
              <RelationshipCircularIcon>
                {activity.icon}
              </RelationshipCircularIcon>
              <RelationshipDetailsPageTitle>
                {activity.title}
              </RelationshipDetailsPageTitle>
              <RelationshipDetailsSubText>
                {activity.subtext}
              </RelationshipDetailsSubText>
              <RelationshipDetailsCard
                onClick={handleCardClick}
                expanded={isCardExpanded}
              >
                <ImageRelationshipCard
                  src={activity.image}
                  expanded={isCardExpanded}
                />
                <SecondColumnDetailsPage>
                  <RelationshipDetailsPageCardTitle expanded={isCardExpanded}>
                    {activity.title}
                  </RelationshipDetailsPageCardTitle>
                  <RelationshipDetailsSubText2 expanded={isCardExpanded}>
                    {activity.details}
                  </RelationshipDetailsSubText2>
                  <ClickHereStyle expanded={isCardExpanded}>
                    Clique e veja todos os detalhes
                  </ClickHereStyle>
                  <RelationshipDetailsCardContent expanded={isCardExpanded}>
                    {activity.details2}
                  </RelationshipDetailsCardContent>
                </SecondColumnDetailsPage>
              </RelationshipDetailsCard>
            </>
          ) : (
            <p>Atividade não encontrada</p>
          )}
        </WrapContent2>
      </RelationshipDetailsPageStyle>
      <Footer />
    </>
  );
}
