import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  ActivitiesCircularIcon,
  ActivitiesDetailsCard,
  ActivitiesDetailsPageStyle,
  ActivityDetailsCardContent,
  ActivityDetailsPageCardTitle,
  ActivityDetailsPageTitle,
  ActivityDetailsSubText,
  ActivityDetailsSubText2,
  ClickHereStyle,
  GoBackButton,
  ImageActivitiesCard,
  SecondColumnDetailsPage,
  StyledArrowIcon,
  WrapBackButton,
  WrapContent,
  WrapContent2,
} from "../../styles/ActivitiesDetailsPage";


import { getNews } from "../../data/services/news";
import { NEWS } from "../../core/app-urls";
import { endpoint } from "../../utils/Constants";

export default function NewsDetailsPage() {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [newsItem, setNewsItem] = useState<any>(null);
  const [imgSrc, setImgSrc] = useState<string>('');
  const fallbackImageUrl = 'URL_DA_IMAGEM_DE_FALLBACK';

  useEffect(() => {
    const fetchNewsDetails = async () => {
      const response = await getNews();
      const newsDetails = response.news.find((item: any) => item.id === parseInt(id!));

      if (newsDetails) {
        setNewsItem(newsDetails);
        setImgSrc(`${endpoint}${newsDetails.imagem_noticia}`);
      }
    };

    if (id) {
      fetchNewsDetails();
    }
  }, [id]);

  const handleCardClick = () => {
    setIsCardExpanded(!isCardExpanded);
  };

  const handleBackButton = () => {
    navigate(NEWS);
  };

  return (
    <>
      <Header />
      <ActivitiesDetailsPageStyle>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para NOTÍCIAS</GoBackButton>
          </WrapBackButton>
        </WrapContent>
        <WrapContent2>
          {newsItem ? (
            <>
              <ActivitiesCircularIcon />
              <ActivityDetailsPageTitle>{newsItem.titulo}</ActivityDetailsPageTitle>
              <ActivityDetailsSubText>{newsItem.descricao}</ActivityDetailsSubText>
              <ActivitiesDetailsCard onClick={handleCardClick} expanded={isCardExpanded}>
                <ImageActivitiesCard
                  src={imgSrc}
                  alt={`Imagem de ${newsItem.titulo}`}
                  expanded={isCardExpanded}
                  onError={() => setImgSrc(fallbackImageUrl)}
                />
                <SecondColumnDetailsPage>
                  <ActivityDetailsPageCardTitle expanded={isCardExpanded}>
                    {newsItem.resumo}
                  </ActivityDetailsPageCardTitle>
                  <ActivityDetailsSubText2 expanded={isCardExpanded}>
                    {newsItem.tag}
                  </ActivityDetailsSubText2>
                  <ClickHereStyle expanded={isCardExpanded}>
                    Clique e veja todos os detalhes
                  </ClickHereStyle>
                  <ActivityDetailsCardContent expanded={isCardExpanded}>
                    {newsItem.descricao}
                  </ActivityDetailsCardContent>
                </SecondColumnDetailsPage>
              </ActivitiesDetailsCard>
            </>
          ) : (
            <p>Notícia não encontrada</p>
          )}
        </WrapContent2>
      </ActivitiesDetailsPageStyle>
      <Footer />
    </>
  );
}
