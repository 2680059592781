import React, { useState, useEffect } from "react";
import Calendar, { CalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";

import {
  EventCard,
  EventDescrip,
  EventInfo,
  EventTitle,
  StyledCalendar,
  StyledEventDate,
  WrapCalendarAndEvents,
  WrapEventList,
  WrapEventTitleAndDescrip,
} from "../../styles/Events";
import {
  getEventosDinamicos,
  EventosPorData,
  Evento,
} from "../../data/services/events_service";

function Calendario() {
  const [date, setDate] = useState<Date>(new Date());
  const [eventos, setEventos] = useState<EventosPorData>({});
  const [mostrarTodosEventos, setMostrarTodosEventos] = useState(true);

  const formatarData = (data: string) => {
    const [ano, mes, dia] = data.split("-").map(Number);
    const dataObj = new Date(ano, mes - 1, dia);

    const diaFormatado = dataObj.toLocaleDateString("pt-BR", {
      day: "2-digit",
    });
    const mesFormatado = dataObj
      .toLocaleDateString("pt-BR", { month: "short" })
      .replace(".", "");

    return `${diaFormatado} ${mesFormatado}`;
  };

  useEffect(() => {
    async function carregarEventos() {
      const eventosDinamicos = await getEventosDinamicos();
      setEventos(eventosDinamicos);
    }
    carregarEventos();
  }, []);

  const onChange: CalendarProps["onChange"] = (value) => {
    if (value instanceof Date) {
      setDate(value);
      setMostrarTodosEventos(false);
    }
  };

  // Função para obter eventos de uma data específica
  const getEventosDoDia = (): Evento[] => {
    const dateString = date.toISOString().split("T")[0];
    return eventos[dateString] || [];
  };

  const getTodosOsEventos = (): Evento[] => {
    return Object.values(eventos).flat();
  };

  return (
    <WrapCalendarAndEvents>
      <StyledCalendar
        onChange={onChange}
        value={date}
        tileClassName={({ date }) => {
          const dateString = date.toISOString().split("T")[0];
          return eventos[dateString] ? "evento" : "";
        }}
      />
      <WrapEventList>
        <ul>
          {mostrarTodosEventos
            ? getTodosOsEventos().map((evento, index) => (
                <EventCard key={index}>
                  <StyledEventDate>
                    {formatarData(evento.data!)}
                  </StyledEventDate>
                  <EventInfo>
                    <EventTitle>{evento.titulo}</EventTitle>
                  </EventInfo>
                </EventCard>
              ))
            : getEventosDoDia().map((evento, index) => (
                <div key={index}>
                  <WrapEventTitleAndDescrip>
                    <EventTitle>{evento.titulo}</EventTitle>
                    <EventDescrip>{evento.descricao}</EventDescrip>
                  </WrapEventTitleAndDescrip>
                </div>
              ))}
        </ul>
      </WrapEventList>
    </WrapCalendarAndEvents>
  );
}

export default Calendario;
