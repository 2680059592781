import styled from "styled-components";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

export const NewsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
 

  @media screen and (max-width: 768px) {

  }
`;

export const TwitterTimeLine = styled.div`
  width: 40%;
  overflow-y: auto;
  height: 690px;
  margin-bottom: 40px;
  

  @media screen and (max-width: 1024px) {
    display: none;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

export const WrapContent = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const NewsPageTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 35pt;
    font-weight: 500;
    color: #414C80;
    align-self: flex-start;
    margin-bottom: 0;

    @media screen and (max-width: 1024px) {
        align-self: center;
     }
`

export const NewsPageFilterText = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 12pt;
    font-weight: 400;
    color: black;
    align-self: flex-start;
    margin-top: 0;
    
    @media screen and (max-width: 1024px) {
        width: 70%;
        align-self: center;
    }
`

export const NewsPageFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 50%;
  align-self: flex-start;
  margin-top: 15px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
`;
interface FilterButtonsProps {
    newColor?: string;
}

export const FilterButtons = styled.button<FilterButtonsProps>`
    background-color: white;
    border-color: ${props => props.newColor || '#FF8787'};
    color: ${props => props.newColor || '#FF8787'};
    border-radius: 7px;
    border-width: 1px;
    width: 100px;
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    border-style: solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`

export const WrapVideos = styled.div`
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 450px;
    background-color: #D9D9D9;
    border-radius: 10px;
    overflow-y: auto;
    margin-bottom: 100px;
    align-self: flex-end;


    img{
        width: 200px;
        align-self: center;
    }

    li{
        display: flex;
        list-style-type: none;
        align-self: center;
        margin-top: 30px;
    }

    a{
        color: inherit;
        text-decoration: none;
    }
`

export const WrapCardsAndTwitter = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const WrapCards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        justify-content: center;
        align-items: center;
    }
`

export const NewsPageMainCards = styled.a`
  background-color: #f5f4f4;
  width: 90%;
  height: auto;
  margin-top: 30px;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 90%;
    flex-direction: column;
    height: auto;
  }
`;

export const ImageMainCard = styled.img`
  max-height: 180px;
  width: 300px;
  padding-left: 20px;
  border-radius: 8px;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    height: auto;
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
    border-radius: 0;
  }
`;

export const StyledArrowIcon = styled(IoArrowForwardCircleOutline)`
  color: #000; 
`;

export const RightPartMainCard = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 95%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

interface CategoryTagProps {
    newColor?: string;
}

export const CategoryTag = styled.div<CategoryTagProps>`
    background-color: ${props => props.newColor || '#85A261'};;
    color: white;
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 10pt;
    font-weight: 500;
    border-radius: 5px;
    width: 65px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 22px;
    margin-left: 30px;
`

export const TitleMainCard = styled.h1`
    color: #000000;
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 18pt;
    margin-top: 2px;
    margin-left: 30px;
    margin-bottom: 0;

    @media screen and (max-width: 1024px) {
        width: 70%;
  }
`

export const KnowMoreMainCard = styled.h1`
    color: #000000;
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 12pt;
    margin-top: 2px;
    margin-left: 30px;
    margin-bottom: 0;
`

export const ContentMainCard = styled.h1`
    color: #000000;
    font-family: "Actor", sans-serif;
    font-weight: 400;
    font-size: 10pt;
    margin-top: 0;
    margin-left: 30px;

    @media screen and (max-width: 1024px) {
        width: 70%;
    }
`

export const WrapKnowMore = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
`

export const WrapGrid = styled.div`
  width: 75%;
  display: grid;
  grid-column-gap: 55px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 250px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const NewsPageSubCards = styled.a`
  text-decoration: none;
  background-color: #f5f4f4;
  width: auto;
  height: 150px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    height: auto;
    flex-direction: column;
  }
`;

export const ImageSubCard = styled.img`
  height: 120px;
  width: 150px;
  padding-left: 20px;
  border-radius: 8px;
  align-self: center;

  @media screen and (max-width: 1024px) {
    height: auto;
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
    border-radius: 0;
  }
`;

interface CategorySubTagProps {
    newColor?: string;
}

export const CategorySubTag = styled.div<CategorySubTagProps>`
    background-color: ${props => props.newColor || '#85A261'};;
    color: white;
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 10pt;
    font-weight: 500;
    border-radius: 5px;
    width: 65px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
`

export const TitleSubCard = styled.h1`
    color: #000000;
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 10pt;
    margin-top: 2px;
    margin-left: 30px;
    margin-bottom: 0;

    @media screen and (max-width: 1024px) {
        width: 70%;
  }
`

export const ContentSubCard = styled.h1`
    color: #000000;
    font-family: "Actor", sans-serif;
    font-weight: 400;
    font-size: 8pt;
    margin-top: 0;
    margin-left: 30px;
    margin-right: 25px;

    @media screen and (max-width: 1024px) {
        width: 70%;
  }
`