import { useEffect, useState } from "react";
import {
  BigImageStyle,
  ColumnSmallCard,
  ContentBigCard,
  HomeStyle,
  MediumImageStyle,
  NewsBigCard,
  NewsMediumCard,
  NewsSmallCard,
  NewsTitle,
  SmallImageStyle,
  TitleBigCard,
  WrapContentSmallCard,
  WrapKnowMore,
  WrapMediumCard,
  WrapNewsCard,
  WrapSmallCard,
  WrapTextBigCard,
} from "../../styles/News";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import NewsModel from "../../data/model/news";
import { getNews } from "../../data/services/news";
import { endpoint } from "../../utils/Constants";

export default function News() {
  const [newsList, setNewsList] = useState<NewsModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNews();
        setNewsList(response.news);
        console.log(newsList, "LISTA NOTICIAs")
      } catch (error) {
        console.error("Erro ao carregar notícias", error);
      }
    };

    fetchData();
  }, []);

  const mainNews = newsList[0];
  const mediumNews = newsList.slice(1, 3);
  const smallNews = newsList.slice(3, 6);

  return (
    <>
      <HomeStyle>
        <NewsTitle>NOTÍCIAS</NewsTitle>
        <WrapNewsCard>
          {mainNews && (
            <a href={`/noticias/${mainNews.id}`} >
              <NewsBigCard>
                <BigImageStyle src={`${endpoint}${mainNews.imagem_noticia}` || ''} alt="Big image" />
                <WrapTextBigCard>
                  <TitleBigCard>{mainNews.titulo}</TitleBigCard>
                  <ContentBigCard>{mainNews.resumo}</ContentBigCard>
                  <WrapKnowMore>
                    <TitleBigCard>Saiba mais</TitleBigCard>
                    <IoArrowForwardCircleOutline size={23} />
                  </WrapKnowMore>
                </WrapTextBigCard>
              </NewsBigCard>
            </a>
          )}

          <WrapMediumCard>
            {mediumNews.map((news, index) => (
              <a key={index} href={`/noticias/${news.id}`} >
                <NewsMediumCard>
                  <MediumImageStyle src={`${endpoint}${news.imagem_noticia}` || ''} alt="Medium image" />
                  <WrapTextBigCard>
                    <TitleBigCard>{news.titulo}</TitleBigCard>
                    <ContentBigCard>{news.resumo}</ContentBigCard>
                    <WrapKnowMore>
                      <TitleBigCard>Saiba mais</TitleBigCard>
                      <IoArrowForwardCircleOutline size={23} />
                    </WrapKnowMore>
                  </WrapTextBigCard>
                </NewsMediumCard>
              </a>
            ))}
          </WrapMediumCard>

          <WrapSmallCard>
            {smallNews.map((news, index) => (
              <a key={index} href={`/noticias/${news.id}`} >
                <NewsSmallCard>
                  <SmallImageStyle src={`${endpoint}${news.imagem_noticia}` || ''} alt="Small Image" />
                  <WrapContentSmallCard>
                    <ColumnSmallCard>
                      <TitleBigCard>{news.titulo}</TitleBigCard>
                      <ContentBigCard>{news.descricao}</ContentBigCard>
                    </ColumnSmallCard>
                    <WrapKnowMore>
                      <IoArrowForwardCircleOutline size={23} />
                    </WrapKnowMore>
                  </WrapContentSmallCard>
                </NewsSmallCard>
              </a>
            ))}
          </WrapSmallCard>
        </WrapNewsCard>
      </HomeStyle>
    </>
  );
}
