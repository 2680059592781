import { endpoint } from "../../utils/Constants";
import HomeItemsModel from "../model/home_items";
import HomeLacosModel from "../model/home_lacos";
import { fetchHomeItems, fetchHomeLacosItems } from "../provider/api";

interface fetchHomeItemsResponse {
    homeItems: HomeItemsModel | null; 
}

interface fetchHomeLacosResponse {
    lacos: HomeLacosModel[] | null; 
}

export async function getHomeItems(): Promise<{ homeItems: HomeItemsModel[] | null }> {
  try {
    const responseData = await fetchHomeItems();

    const homeItems = responseData.data.map((item: any) => {
      const attributes = item.attributes;

      
      const slideHomeData = attributes.slide_home?.data
        ? Object.values(attributes.slide_home.data.attributes.formats).map((format: any) => {
            return `${endpoint}${format.url}`;
          })
        : [];

      return new HomeItemsModel(
        item.id,
        attributes.nome_botao,
        attributes.url_botao,
        slideHomeData, 
        attributes.createdAt,
        attributes.updatedAt,
        attributes.publishedAt
      );
    });

    return { homeItems };
  } catch (error: any) {
    console.error(`Erro (${error.response?.status}) ao buscar itens`);
    console.log(error.toString());
    return { homeItems: null };
  }
}
  
  


export async function getHomeLacos(): Promise<fetchHomeLacosResponse> {
    try {
        const responseData = await fetchHomeLacosItems(); 

        const items = responseData.data.map((item: any) => {
            const attributes = item.attributes;
            const slideHomeData = attributes.imagem_laco?.data?.attributes?.url || null;

            return new HomeLacosModel(
                item.id,
                attributes.titulo,
                attributes.descricao,
                attributes.link_laco,
                slideHomeData, 
                attributes.createdAt,
                attributes.updatedAt,
                attributes.publishedAt,
            );
        });


        return { lacos: items };

    } catch (error: any) {
        console.error(`Erro (${error.response?.status}) ao buscar laços`);
        console.log(error.toString());
        return { lacos: [] }; 
    }
}
