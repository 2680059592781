import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  ActivitiesCircularIcon,
  ActivitiesDetailsCard,
  ActivitiesDetailsPageStyle,
  ActivityDetailsCardContent,
  ActivityDetailsPageCardTitle,
  ActivityDetailsPageTitle,
  ActivityDetailsSubText,
  ActivityDetailsSubText2,
  ClickHereStyle,
  GoBackButton,
  ImageActivitiesCard,
  SecondColumnDetailsPage,
  StyledArrowIcon,
  WrapBackButton,
  WrapContent,
  WrapContent2,
} from "../../styles/ActivitiesDetailsPage";
import { getPartners } from "../../data/services/activities_service"; 
import { ACTIVITIES } from "../../core/app-urls";
import { endpoint } from "../../utils/Constants";

export default function ActivitiesDetailsPage() {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [activity, setActivity] = useState<any>(null); 
  const [imgSrc, setImgSrc] = useState<string>('');
  const fallbackImageUrl = 'URL_DA_IMAGEM_DE_FALLBACK';

  useEffect(() => {
    const fetchActivityDetails = async () => {
      const response = await getPartners();
      const activityDetails = response.partners.find((item) => item.id === parseInt(id!));

      if (activityDetails) {
        setActivity(activityDetails);
        setImgSrc(`${endpoint}${activityDetails.imagem_Parceiro_Url}`);
      }
    };

    if (id) {
      fetchActivityDetails();
    }
  }, [id]);

  const handleCardClick = () => {
    setIsCardExpanded(!isCardExpanded);
  };

  const handleBackButton = () => {
    navigate(ACTIVITIES);
  };

  return (
    <>
      <Header />
      <ActivitiesDetailsPageStyle>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para PARCERIAS</GoBackButton>
          </WrapBackButton>
        </WrapContent>
        <WrapContent2>
          {activity ? (
            <>
              <ActivitiesCircularIcon>

              </ActivitiesCircularIcon>
              <ActivityDetailsPageTitle>
                {activity.titulo_Parceiro}
              </ActivityDetailsPageTitle>
              <ActivityDetailsSubText>
                {activity.sub_Titulo_Parceiro}
              </ActivityDetailsSubText>
              <ActivitiesDetailsCard
                onClick={handleCardClick}
                expanded={isCardExpanded}
              >
                <ImageActivitiesCard
                  src={imgSrc}
                  alt={`Imagem de ${activity.titulo_Parceiro}`}
                  expanded={isCardExpanded}
                  onError={() => setImgSrc(fallbackImageUrl)}
                />
                <SecondColumnDetailsPage>
                  <ActivityDetailsPageCardTitle expanded={isCardExpanded}>
                    {activity.titulo_card}
                  </ActivityDetailsPageCardTitle>
                  <ActivityDetailsSubText2 expanded={isCardExpanded}>
                    {activity.sub_titulo_card}
                  </ActivityDetailsSubText2>
                  <ClickHereStyle expanded={isCardExpanded}>
                    Clique e veja todos os detalhes
                  </ClickHereStyle>
                  <ActivityDetailsCardContent expanded={isCardExpanded}>
                    {activity.descricao_Parceiro} 
                  </ActivityDetailsCardContent>
                </SecondColumnDetailsPage>
              </ActivitiesDetailsCard>
            </>
          ) : (
            <p>Atividade não encontrada</p>
          )}
        </WrapContent2>
      </ActivitiesDetailsPageStyle>
      <Footer />
    </>
  );
}
