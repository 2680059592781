import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonIconLeft,
  ButtonIconRight,
  CalendarContainer,
  CalendarTitle,
  EventsPageContainer,
  EventsPageSlider,
  EventsPageSliderCard,
  EventsPageSub,
  EventsPageTitle,
  ImageSliderCard,
  NextEventContent,
  NextEventFirstColumn,
  NextEventSecondColumn,
  NextEventsCardNumber,
  NextEventsCardTitle,
  NextEventsContainer,
  NextEventsTitle,
  RightArrow,
  SliderCardSubtitle,
  SliderCardTitle,
  WrapCalendar,
  WrapContent,
} from "../../styles/EventsPage";
import Calendario from "../../components/Calendar/Calendar";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HAPPYH from "../../assets/img/happyHour.jpg";
import ALMOCO from "../../assets/img/almoco.png";
import TARDE from "../../assets/img/tarde.png";
import LIVRO from "../../assets/img/livro.jpeg";
import MAES from "../../assets/img/maes.jpg";
import PAIS from "../../assets/img/pais.jpg";
import JUNINA from "../../assets/img/junina.jpg";
import NATALINA from "../../assets/img/natalina.jpg";
import PASSEIOS from "../../assets/img/passeios.jpg";
import APOSENTADOS from "../../assets/img/aposentados.jpg";
import RIGHTARROW from "../../assets/img/rightArrow.png";
import { RELATIONSHIP } from "../../core/app-urls";
import { getEventosDinamicos, EventosPorData } from "../../data/services/events_service";

export default function EventsPage() {
  const [eventos, setEventos] = useState<EventosPorData>({}); 
  const carousel = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function carregarEventos() {
      const eventosDinamicos = await getEventosDinamicos(); 
      setEventos(eventosDinamicos);
    }
    carregarEventos();
  }, []);

  const eventCards = [
    {
      image: HAPPYH,
      title: "HappyHour",
      subtitle: "",
    },
    {
      image: ALMOCO,
      title: "Almoço Mensal",
      subtitle: "",
    },
    {
      image: LIVRO,
      title: "Clube do livro",
      subtitle: "",
    },
    {
      image: JUNINA,
      title: "Festa Junina",
      subtitle: "",
    },
    {
      image: MAES,
      title: "Baile das mães",
      subtitle: "",
    },
    {
      image: PAIS,
      title: "Dia dos pais",
      subtitle: "",
    },
    {
      image: APOSENTADOS,
      title: "Festa dos aposentados",
      subtitle: "",
    },
    {
      image: NATALINA,
      title: "Festa natalina",
      subtitle: "",
    },
    {
      image: PASSEIOS,
      title: "Viagens e passeios",
      subtitle: "",
    },
  ];

  const handleLeftClick = (e: any) => {
    e.preventDefault();
    const slider = carousel.current! as HTMLElement;
    const cardWidth = slider.querySelector("div")?.offsetWidth || 0;
    const distanceToTravel =
      window.innerWidth <= 1024 ? cardWidth : slider.offsetWidth;
    const framesPerSecond = 20;
    const durationInSeconds = 2;
    const totalFrames = framesPerSecond * durationInSeconds;
    const scrollAmount = distanceToTravel / totalFrames;

    let currentScroll = slider.scrollLeft;
    let currentFrame = 0;

    const slideTimer = setInterval(() => {
      currentScroll -= scrollAmount;
      slider.scrollLeft = currentScroll;

      currentFrame++;

      if (currentFrame >= totalFrames) {
        clearInterval(slideTimer);
      }
    }, 1000 / framesPerSecond);
  };

  const handleRightClick = (e: any) => {
    e.preventDefault();
    const slider = carousel.current! as HTMLElement;
    const cardWidth = slider.querySelector("div")?.offsetWidth || 0;
    const distanceToTravel =
      window.innerWidth <= 1024 ? cardWidth : slider.offsetWidth;
    const framesPerSecond = 20;
    const durationInSeconds = 2;
    const totalFrames = framesPerSecond * durationInSeconds;
    const scrollAmount = distanceToTravel / totalFrames;

    let currentScroll = slider.scrollLeft;
    let currentFrame = 0;

    const slideTimer = setInterval(() => {
      currentScroll += scrollAmount;
      slider.scrollLeft = currentScroll;

      currentFrame++;

      if (currentFrame >= totalFrames) {
        clearInterval(slideTimer);
      }
    }, 1000 / framesPerSecond);
  };

  const handleCardsDetails = (id: any) => {
    if (id == 2) {
      navigate(`${RELATIONSHIP}/1`);
    } else {
      if (id == 8) {
        navigate(`${RELATIONSHIP}/2`);
      }
    }
  };

  const getFutureEvents = () => {
    const today = new Date();
    return Object.entries(eventos)
      .filter(([date]) => new Date(date) >= today)
      .sort(
        ([dateA], [dateB]) =>
          new Date(dateA).getTime() - new Date(dateB).getTime()
      );
  };

  const futureEvents = getFutureEvents();

  return (
    <>
      <Header />
      <EventsPageContainer>
        <WrapContent>
          <EventsPageTitle>Eventos e Atividades</EventsPageTitle>
          <EventsPageSub>
            Veja nossos principais eventos e mais abaixo confira o próximo
            evento e nossa agenda.
          </EventsPageSub>
        </WrapContent>
        <ButtonIconLeft onClick={handleLeftClick}>
          <RightArrow
            src={RIGHTARROW}
            style={{ transform: "rotate(180deg)" }}
          />
        </ButtonIconLeft>

        <EventsPageSlider ref={carousel}>
          {eventCards.map((item, index) => (
            <EventsPageSliderCard
              key={index}
              onClick={() => handleCardsDetails(index)}
            >
              <ImageSliderCard src={item.image} />
              <SliderCardTitle>{item.title}</SliderCardTitle>
              <SliderCardSubtitle>{item.subtitle}</SliderCardSubtitle>
            </EventsPageSliderCard>
          ))}
        </EventsPageSlider>

        <ButtonIconRight onClick={handleRightClick}>
          <RightArrow src={RIGHTARROW} />
        </ButtonIconRight>
        <WrapContent>
          <NextEventsTitle>Próximos eventos:</NextEventsTitle>
          {futureEvents.length > 0 ? (
            futureEvents.map(([date, eventos], index) => (
              <NextEventsContainer key={index}>
                <NextEventFirstColumn>
                  <NextEventsCardTitle>{eventos[0].titulo}</NextEventsCardTitle>
                  <NextEventsCardNumber>
                    {new Date(date).getDate() + 1}
                  </NextEventsCardNumber>
                  <NextEventsCardTitle>
                    {new Date(date).toLocaleString("default", {
                      month: "long",
                    })}
                  </NextEventsCardTitle>
                </NextEventFirstColumn>
                <NextEventSecondColumn>
                  <NextEventContent>{eventos[0].descricao}</NextEventContent>
                </NextEventSecondColumn>
              </NextEventsContainer>
            ))
          ) : (
            <p>Não há eventos futuros.</p>
          )}
        </WrapContent>
        <CalendarContainer>
          <WrapCalendar>
            <CalendarTitle>CALENDÁRIO DE ATIVIDADES</CalendarTitle>
            <Calendario />
          </WrapCalendar>
        </CalendarContainer>
      </EventsPageContainer>
      <Footer />
    </>
  );
}
