import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { GALLERY } from "../../core/app-urls";
import {
  GalleryItemsPageStyle,
  GalleryItemsStyle,
  Pics,
  WrapBackButton,
  StyledArrowIcon,
  GoBackButton,
  Model,
  CloseIcon,
} from "../../styles/GalleryItems";
import { getAlbums } from "../../data/services/Gallery_service"; 
import { endpoint } from "../../utils/Constants";

const GalleryItemsPage: React.FC = () => {
  const { albumId } = useParams<{ albumId: string }>(); 
  const navigate = useNavigate();
  const [photos, setPhotos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState<string>("");

  useEffect(() => {
    const fetchAlbumPhotos = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getAlbums();
        const allAlbums = response.albums;

        // Encontrar o álbum específico com base no albumId da URL
        const selectedAlbum = allAlbums!.find((album: any) => album.id === parseInt(albumId!, 10));

        // Verificar se o álbum foi encontrado e definir as fotos
        if (selectedAlbum) {
          setPhotos(selectedAlbum.fotos!);
        } else {
          setError("Álbum não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar fotos:", error);
        setError("Erro ao carregar fotos.");
      } finally {
        setLoading(false);
      }
    };

    if (albumId) {
      fetchAlbumPhotos(); 
    }
  }, [albumId]);

  const getImg = (imgSrc: string) => {
    setTempImgSrc(imgSrc); // Define a URL da imagem para exibição no modal
    setModel(true); // Abre o modal
  };

  const handleClosePic = () => {
    setModel(false); // Fecha o modal
    setTempImgSrc(""); // Limpa a URL da imagem
  };

  const handleClickGallery = () => {
    navigate(GALLERY); // Voltar para a galeria
  };

  return (
    <>
      <Header />
      <GalleryItemsPageStyle>
        <WrapBackButton onClick={handleClickGallery}>
          <StyledArrowIcon size={23} />
          <GoBackButton>VOLTAR PARA GALERIA</GoBackButton>
        </WrapBackButton>

        {loading ? (
          <h3 style={{ textAlign: "center" }}>Carregando fotos...</h3>
        ) : error ? (
          <h3 style={{ textAlign: "center" }}>{error}</h3>
        ) : photos.length > 0 ? (
          <GalleryItemsStyle>
            {photos.map((photo) => (
              <Pics key={photo.id} onClick={() => getImg(photo.url)}>
                <img
                  src={`${endpoint}${photo.url}`}
                  alt={`Photo ${photo.id}`}
                  style={{ width: "100%" }}
                />
              </Pics>
            ))}
          </GalleryItemsStyle>
        ) : (
          <h3 style={{ textAlign: "center" }}>Ainda não há fotos para este álbum.</h3>
        )}

        {/* Renderizar o modal se estiver ativo e a URL da imagem estiver definida */}
        {model && tempImgSrc && (
          <Model open="model">
            <img src={`${endpoint}${tempImgSrc}`} alt="Selected" style={{ width: "80%", height: "auto" }} />
            <CloseIcon onClick={handleClosePic}>Fechar e voltar</CloseIcon>
          </Model>
        )}
      </GalleryItemsPageStyle>
    </>
  );
};

export default GalleryItemsPage;
