import {
  ColumnsContent,
  ColumnsContent2,
  FirstColumn,
  FirstColumnTitle,
  FooterContainer,
  LogoStyle,
  RightColumn,
  Sub,
  VerticalDivisor,
  WrapIconContent,
  WrapTitleSub,
} from "../../styles/Footer";
import LOGO from "../../assets/img/abace_logo_white.png";
import { LuAtSign } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";

export default function Footer() {
  return (
    <>
      <FooterContainer>
        <FirstColumn>
          <WrapTitleSub>
            <FirstColumnTitle>PÁGINAS</FirstColumnTitle>
            <Sub />
          </WrapTitleSub>
          <ColumnsContent>INÍCIO</ColumnsContent>
          <ColumnsContent>A ABACE</ColumnsContent>
          <ColumnsContent>GALERIA</ColumnsContent>
          <ColumnsContent>PROJETOS E PARCERIAS</ColumnsContent>
          <ColumnsContent>NOTÍCIAS</ColumnsContent>
          <ColumnsContent>EVENTOS</ColumnsContent>
        </FirstColumn>
        <FirstColumn>
          <WrapTitleSub>
            <FirstColumnTitle>UTILIDADES</FirstColumnTitle>
            <Sub />
          </WrapTitleSub>
          <ColumnsContent>CONTATO DA ABACE</ColumnsContent>
          <ColumnsContent>FALE CONOSCO</ColumnsContent>
        </FirstColumn>
        <VerticalDivisor />
        <RightColumn>
          <LogoStyle src={LOGO} />
          <WrapIconContent>
            <ColumnsContent2>
              <LuAtSign size={20} />
            </ColumnsContent2>
            <ColumnsContent> E-MAIL: ABACE@ABACE.COM.BR</ColumnsContent>
          </WrapIconContent>
          <WrapIconContent>
            <ColumnsContent2>
              <BsTelephone size={20} />
            </ColumnsContent2>
            <ColumnsContent>TELEFONE: (61) 99999-9898</ColumnsContent>
          </WrapIconContent>
          <WrapIconContent>
            <ColumnsContent2>
              <FaWhatsapp size={20} />
            </ColumnsContent2>
            <ColumnsContent>WHATSAPP: (61) 87878-8989</ColumnsContent>
          </WrapIconContent>
          <WrapIconContent>
            <ColumnsContent2>
              <HiOutlineLocationMarker size={20} />
            </ColumnsContent2>
            <ColumnsContent>
              LOCALIZAÇÃO: NO CLUBE ASBAC BRASÍLIA - Conjunto 31 - Via L4 Sul,
              SCES Trecho 2, Brasília - DF, 70200-002
            </ColumnsContent>
          </WrapIconContent>
        </RightColumn>
      </FooterContainer>
    </>
  );
}
