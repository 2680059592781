import React, { useEffect, useState } from "react";
import {
  Ellipse,
  EllipseSecondText,
  EllipseText,
  Expansor,
  ProfileImage,
  RelationShipCards,
  RelationShipStyle,
  RelationshipCardContent,
  RelationshipCardTitle,
  RowCards,
} from "../../styles/Relationship";
import { useNavigate } from "react-router-dom";
import { EVENTS, RELATIONSHIP } from "../../core/app-urls";
import HomeLacosModel from "../../data/model/home_lacos";
import { getHomeLacos } from "../../data/services/home_service";
import { endpoint } from "../../utils/Constants";

export default function RelationShip() {
  const navigate = useNavigate();
  const [lacos, setLacos] = useState<HomeLacosModel[]>([]);
  const [imgSrc, setImgSrc] = useState<string[]>([]);

  useEffect(() => {
    const fetchLacos = async () => {
      const response = await getHomeLacos();
      if (response.lacos) {
        setLacos(response.lacos);
        setImgSrc(
          response.lacos.map(
            (laco) => `${endpoint}${laco.slide_Laco_Url}`
          )
        );
      }
    };
    fetchLacos();
  }, []);

  const handleRelationshipDetails = (id: number) => {
    // navigate(`${RELATIONSHIP}/${id}`);
  };

  const handleEvents = () => {
    navigate(EVENTS);
  };

  return (
    <RelationShipStyle>
      <Expansor />
      <Ellipse>
        <EllipseText>ESTAMOS AQUI PARA</EllipseText>
        <EllipseSecondText>CRIAR LAÇOS</EllipseSecondText>
      </Ellipse>
      <RowCards>
        {lacos.map((laco, index) => {
          return (
            <RelationShipCards key={index} onClick={() => handleRelationshipDetails(laco.id!)}>
              <ProfileImage 
                src={imgSrc[index]} 
                alt={`Imagem de ${laco.titulo}`} 
                onError={() => {
                  const newImgSrc = [...imgSrc];
                  newImgSrc[index] = 'URL_DA_IMAGEM_DE_FALLBACK';
                  setImgSrc(newImgSrc);
                }}
              />
              <RelationshipCardTitle>{laco.titulo}</RelationshipCardTitle>
              <RelationshipCardContent>{laco.descricao}</RelationshipCardContent>
              {/* <RelationshipCardContent>
                Clique aqui e saiba mais.
              </RelationshipCardContent> */}
            </RelationShipCards>
          );
        })}
      </RowCards>
    </RelationShipStyle>
  );
}