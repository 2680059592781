import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Slider from "../../components/Slider/Slider";
import Events from "../EventsHome";
import Maintence from "../Maintence";
import News from "../NewsHome";
import Partners from "../PartnersHome";
import RelationShip from "../RelationshipHome";

export default function Home() {
  return (
    <>
      {/* <Maintence /> */}
      <Header />
      <Slider />
      <News />
      <Events />
      <RelationShip />
      <Partners />
      {/* <Footer /> */}
    </>
  );
}
