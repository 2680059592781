import styled from "styled-components";

export const HorizontalDivision = styled.div`
    width: 100%;
    border-bottom: 1px solid #000000; 
    margin-top: 862px;

    @media screen and (max-width: 1024px) {
        display: block;    
    }
`;

export const HorizontalDivision2 = styled.div`
    width: 100%;
    border-bottom: 1px solid #000000; 
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
        display: block;    
    }
`;

export const RectangleDivisor = styled.div`
    width: 300px;
    height: 80px;
    background-image: linear-gradient(to bottom, #E9F5F9 50%, #ffffff 50%);
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 2214px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1024px) {
        width: 200px;
        height: 60px;
    }
`;

export const RectangleDivisor2 = styled.div`
    width: 300px;
    height: 80px;
    background-image: linear-gradient(to bottom, #ffff 50%, #ffffff 50%);
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 2420px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1024px) {
        width: 200px;
        height: 60px;
    }
`;

export const RectangleDivisorText = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 700;
    text-align: center;
    align-self: center;
    padding-top: 12px;

    @media screen and (max-width: 1024px) {
        font-size: 12pt;
    }
`;

export const PartnersStyle = styled.div`
    background-color: white;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        height: auto;
    }
`;

export const FirstRowPartners = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 45px;

    @media screen and (max-width: 1024px) {
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
    }
`;

export const SecondRowPartners = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 38px;
    margin-left: 55px;
    margin-right: 55px;

    @media screen and (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-self: center;
        gap: 30px;
        padding-top: 20px;
        margin-left: 0;
        margin-right: 0;
    }
`;

export const PartnersLogos = styled.img`
    max-height: 100px;
    width: 175px;
    align-self: center;

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px;
    }
`;

export const PartnersLogos2 = styled.img`
    height: 55px;
    width: 380px;

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 150px;
        display: none;
    }
`;

export const PartnersLogos3 = styled.img`
    height: 110px;
    width: 160px;
    padding-left: 40px;

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px;
        padding-left: 0;
    }
`;

export const PartnersLogos4 = styled.img`
    height: 110px;
    width: 250px;
    padding-left: 40px;

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 150px;
        padding-left: 0;
    }
`;
