import styled from "styled-components";

export const HomeStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`

export const NewsTitle = styled.h1`
    color: #000000;
    font-family: "Belleza", sans-serif;
    font-weight: 400;
    font-size: 18pt;
    margin-right: 990px;
    /* align-self: self-start; */
`

export const WrapNewsCard = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    a{
        text-decoration: none;
        text-decoration-color: black;
    }
`

export const NewsBigCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 330px;
    height: 330px;
    background-color: #E9E9E9;
    /* margin-left: 35px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media screen and (max-width: 1024px) {
        width: 90%;
        align-self: center;
        justify-content: center;
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
        padding: 5px;
    }
`

export const BigImageStyle = styled.img`
    height: 65%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    /* align-self: center;  */
    /* padding-left: 40px; */

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px ;
        text-align: center;
        align-self: center;
        /* padding-left: 24px; */
    }
`

export const WrapTextBigCard = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`

export const TitleBigCard = styled.h1`
    color: #000000;
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 10pt;
    margin-top: 2px;
    margin-bottom: 4px;
`

export const ContentBigCard = styled.h1`
    color: #000000;
    font-family: "Actor", sans-serif;
    font-weight: 400;
    font-size: 10pt;
    margin-top: 5px;
`

export const WrapKnowMore = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const NewsMediumCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 345px;
    height: 140px;
    background-color: #E9E9E9;
    margin-left: 35px;

    @media screen and (max-width: 1024px) {
        width: 80%;
        margin: 10px auto 0 auto;
        flex-direction: column;
        text-align: center;
        padding: 5px;
    }
`

export const WrapMediumCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px
`

export const MediumImageStyle = styled.img`
    height: 65%;
    width: 35%;
    border-radius: 10px;
    margin-left: 15px;
    object-fit: cover;
    /* align-self: center;  */
    /* padding-left: 40px; */

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px ;
    }
`

export const WrapSmallCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media screen and (max-width: 1024px) {
        align-items: center;
        justify-content: center;
    }
`

export const NewsSmallCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    height: auto;
    background-color: #E9E9E9;
    margin-left: 35px;

    @media screen and (max-width: 1024px) {
        width: 80%;
        align-self: center;
        margin: 10px auto 0 auto;
        flex-direction: column;
        text-align: center;
        padding: 5px;
    }
`

export const SmallImageStyle = styled.img`
    /* height: 45%; */
    width: 25%;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 3px;
    
    /* align-self: center;  */
    /* padding-left: 40px; */

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px ;
        /* padding-left: 24px; */
    }
`

export const WrapContentSmallCard = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 2px;
`
export const ColumnSmallCard = styled.div`
    display: flex;
    flex-direction: column;
`