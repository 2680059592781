import styled from "styled-components";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export const RelationShipStyle = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #E9F5F9;
    width: 100%;
    height: 900px;
    margin: 0;
    gap: 60px;
    padding: 0;
    position: absolute;
    justify-content: center;
    top: 1312px;
    z-index: 1;

    @media screen and (max-width: 768px) {
        /* padding: 20px; */
        gap: 30px;
        top: 1420px;
        align-items: center;
    }
`

export const Expansor = styled.div`
    width: 100%;
    height: 10px;
    background-color: #E9F5F9;

    @media screen and (max-width: 1024px) {
        /* display: none;     */
    }
`

export const RowCardsWrapper = styled.div`
  position: relative; 
  max-height: 700px; 
  overflow-y: auto; 
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%; 
  
  @media screen and (max-width: 1024px) {
    max-height: 500px; 
  }

  @media screen and (max-width: 768px) {
    max-height: 400px; 
  }
`;

export const RowCards = styled.div`
    padding-top: 28px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 150px;
    column-gap: 400px;
    align-self: center;

    @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`

export const RelationShipCards = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    height: auto;
    width: 350px;
    flex-direction: column;
    display: flex;
    align-items: center;
    position: relative; 
    /* cursor: pointer; */

    @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 300px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`

export const RelationshipCardTitle = styled.h1`
    color: #1270B0;
    font-family: "Belleza", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    padding-bottom: 25px;

    @media screen and (max-width: 1024px) {
    font-size: 14pt;
  }

  @media screen and (max-width: 768px) {
    font-size: 13pt;
  }
`

export const RelationshipCardContent = styled.h1` 
    color: black;
    font-family: "Actor", sans-serif;
    font-size: 10pt;
    font-weight: 400;
    margin-left: 12px;
    margin-right: 12px;
    text-align: justify;
    align-self: self-start;

    @media screen and (max-width: 1024px) {
    font-size: 9pt;
  }

  @media screen and (max-width: 768px) {
    font-size: 8pt;
  }
`

export const ProfileImage = styled.img`
    position: absolute;
    top: -22%;
    left: -20%; 
    width: 130px; 
    height: 110px; 
    border-radius: 50%;
    object-fit: cover;
    z-index: 2; 

    @media screen and (max-width: 1024px) {
        width: 120px;
        height: 100px;
        left: -10px;
        top: -18px;
     }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 80px;
  }
`

export const RelationshipDetailsPageStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const WrapContent = styled.div`
    display: flex;
    align-self: center;
    width: 75%;
`

export const WrapContent2 = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    width: 75%;
    flex-direction: column;
`

export const WrapBackButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    gap: 15px
`

export const GoBackButton = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    align-self: self-start;
    color: black;
    background-color: transparent;
    border: none;
    margin-top: 55px;
    cursor: pointer;
`

export const StyledArrowIcon = styled(IoArrowBackCircleOutline)`
  color: #000; 
  margin-top: 45px;
`

export const RelationshipCircularIcon = styled.div`
    background-color: white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-self: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
    display: flex;
`

export const RelationshipDetailsPageTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
`;

export const RelationshipDetailsSubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  width: 500px;
  color: black;
  align-self: center;
  text-align: center;
  margin-top: 0;
`;

interface RelationshipCardProps {
  expanded?: boolean;
}

export const RelationshipDetailsCard = styled.div<RelationshipCardProps>`
    width: 100%;
    height: ${props => props.expanded ? '500px' : '200px'};
    transition: height 1.2s ease;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #F1F1F1;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: flex;
    flex-direction: ${props => props.expanded ? 'column' : 'row'};
`

export const ImageRelationshipCard = styled.img<RelationshipCardProps>`
    height: 180px;
    width: 300px; 
    border-radius: 8px;
    object-fit: cover;
    align-self: center;
    margin-left: ${props => props.expanded ? '0' : '20px'};
    margin-top: ${props => props.expanded ? '15px' : '0'};

    @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px ;
        padding-left: 24px;
    }
`

export const RelationshipDetailsPageCardTitle = styled.h1<RelationshipCardProps>`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 20pt;
  font-weight: 500;
  color: #414c80;
  align-self: ${props => props.expanded ? 'center' : 'self-start'};
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15px;
`

export const SecondColumnDetailsPage = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
`

export const RelationshipDetailsSubText2 = styled.h1<RelationshipCardProps>`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: black;
  align-self: ${props => props.expanded ? 'center' : 'self-start'};
  margin-top: 0;
  margin-left: 15px;
`

export const RelationshipDetailsCardContent = styled.h1<RelationshipCardProps>`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: black;
  display: ${props => props.expanded ? 'block' : 'none'};
  align-self: center;
  text-align: center;
  margin-top: 15px;
  width: 95%;
`

export const ClickHereStyle = styled.h1<RelationshipCardProps>`
  display: ${props => props.expanded ? 'none' : 'block'};
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 12pt;
  font-weight: 600;
  color: blue;
  margin-left: 15px;
  margin-top: 45px;
  opacity: .7;
`

export const Ellipse = styled.div`
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
        width: 90%;
        height: 85px;
        background-color: #FFFFFF;
        border-radius: 50px / 40px; 
        position: absolute;
        align-items: center;
        justify-content: center;
        top: -10px;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
`;

export const EllipseText = styled.h1`
    color: #1270B0;
    font-family: "Belleza", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    text-align: center;
    align-self: center;
    padding-left: 1px;
    margin-bottom: 10px;
    padding-top: 2px;
`

export const EllipseSecondText = styled.h1`
    color: #1270B0;
    font-family: "Belleza", sans-serif;
    font-weight: 400;
    font-size: 20pt;
    text-align: center;
    align-self: center;
    padding-left: 2px;
    margin-top: 0;
`


