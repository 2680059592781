import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 100%;
  height: 350px;
  background-image: linear-gradient(to right, #d5f3fc, #ffffff 70%);
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  display: flex;

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 200px;
  position: absolute;
  top: 150px;
  left: 210px;

  @media screen and (max-width: 768px) {
    position: static;
    margin-right: 0;
    text-align: center;
    padding: 20px;
  }
`;

export const SliderMediaIconsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  gap: 5px;
  z-index: 99;

  img {
    cursor: pointer;
    width: 45px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 10px;

    img {
      width: 35px;
    }
  }
`;

export const WelcomeText = styled.h1`
    font-family: "Belleza", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    margin-bottom: -20px;
`

export const AbaceText = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 50pt;
    font-weight: 500;
    color: #414C80;
    margin-bottom: -28px;
    margin-top: -5px;
`

export const SubtitleText = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 500;
    margin: 0;
    z-index: 99;
`

export const WrapButtonAndPagination = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
`;

export const AppButton = styled.a`
  font-family: "Baloo Bhai 2", sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11pt;
  width: 150px;
  background-color: #6675c1;
  text-decoration: none;
  border-radius: 5px;
  padding: 3px;
  margin-top: 20px;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 99;

  @media screen and (max-width: 768px) {
    font-size: 10pt;
    width: 140px;
  }
`;

export const PaginationDots = styled.div`
    display: flex;
    margin-bottom: 9px;
`

export const Dot = styled.div<{ active?: boolean }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ active }) => (active ? "#6675C1" : "#E0E0E0")};
    margin: 0 5px;
`

export const CellphoneImage = styled.img`
  position: absolute;
  right: 140px;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 370px;
  width: 700px;
  padding-left: 900px;

  @media screen and (max-width: 1024px) {
    position: static;
    width: 2%;
    height: auto;
    padding-left: 0;
    margin-top: 20px;
  }
`;


export const CellphoneImage2 = styled.img`
    
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* object-fit: cover; */
    /* align-self: center;  */
    /* padding-left: 40px; */

    /* @media screen and (max-width: 1024px) {
        height: 60px;
        width: 100px ;
        padding-left: 24px;
    } */
`

export const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 110px;
  top: 210px;

  @media screen and (max-width: 768px) {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: none;
  }
`;

export const ButtonIconRight = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 110px;
  top: 280px;

  @media screen and (max-width: 768px) {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: none;
  }
`;