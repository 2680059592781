import { bearer, endpoint } from "../../utils/Constants";

const homeItemsURL = `${endpoint}/api/homes?populate=*`
const homeLacosURL = `${endpoint}/api/lacos?populate=*`;
const parceirosURL = `${endpoint}/api/parceiros?populate=*`;
const albumURL = `${endpoint}/api/galerias?populate=*`;
const eventsURL = `${endpoint}/api/eventos?populate=*`;
const newsURL = `${endpoint}/api/noticias?populate=*`;
const partnersHomeURL = `${endpoint}/api/parceiros-abaces?populate=*`;
const linkHomeURL = `${endpoint}/api/links-uteis?populate=*`;


export const fetchHomeItems = async () => {
    const response = await fetch(
        `${homeItemsURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API home Items");

    return responseData; 
}

export const fetchHomeLacosItems = async () => {
    const response = await fetch(
        `${homeLacosURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API home lacos");

    return responseData; 
}

export const fetchActivitiesList = async () => {
    const response = await fetch(
        `${parceirosURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Activities List");

    return responseData; 
}


export const fetchAlbums = async () => {
    const response = await fetch(
        `${albumURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Albums");

    return responseData; 
}

export const fetchEvents = async () => {
    const response = await fetch(
        `${eventsURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Eventos");

    return responseData; 
}


export const fetchNews = async () => {
    const response = await fetch(
        `${newsURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Notícias");

    return responseData; 
}

export const fetchPartnersHome = async () => {
    const response = await fetch(
        `${partnersHomeURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Partners Home");

    return responseData; 
}

export const fetchLinksHome = async () => {
    const response = await fetch(
        `${linkHomeURL}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        }
    );

    const responseData = await response.json();

    console.log(responseData, "Retorno API Partners Home");

    return responseData; 
}